import dayjs from 'dayjs'
import CONSTANTS from './constants'

export const contentPath = (path) => `${CONSTANTS.CONTENT_ROOT}/${path}`
export const fixturesPath = (path) => `${CONSTANTS.FIXTURES_ROOT}/${path}`
export const mediaUrl = (path) => `${CONSTANTS.MEDIA_URI}${path}`
export const portalUrl = () => CONSTANTS.PORTAL_URI
export const upkUrl = () => CONSTANTS.UPK_URI
export const formUrl = (formId) => `${CONSTANTS.FORM_URI}/${formId}`
export const imageNotFound = (event, variant = 'blank') => {
  event.target.src = `${CONSTANTS.MEDIA_URI}/images/missing-${variant}.jpg`
}

export const formAction = (id) => `window.open('${formUrl(id)}','_blank','width=770,height=500,top=100,left=100')`
export const formPopup = (id, label, classes = '') => `
  <button is-text form-cue onclick="${formAction(id)}" class="${classes}">
    ${label}
  </button>
`

export const modalAction = (source, allowPassiveDismiss) => `window.setModalSource('${source}', ${allowPassiveDismiss})`
export const modalPopup = (source, allowPassiveDismiss, label, classes = '') => `
  <button is-text modal-cue onclick="${modalAction(source, allowPassiveDismiss)}" class="${classes}">
    ${label}
  </button>
`

export const removeDuplicates = (array) => [...new Set(array)]
export const joinClasses = (classList) => classList.filter(Boolean).join(' ')

export const flattenCollection = (list = [], level = 0, hasNamedParent = false) => list.reduce(
  (acc, {
    name,
    path,
    children = [],
    meta = {},
  }) => {
    let collection = acc
    const root = level === 0
    const nested = !root

    if (!meta.hidden) {
      acc.push({
        name,
        path,
        meta,
        level,
        root,
        nested,
        hasNamedParent,
      })
    }

    if (children?.length) {
      collection = collection.concat(flattenCollection(children, level + 1, !!name))
    }

    return collection
  }, [],
)

export const branches = (branch, property, flatten = false) => {
  const stack = []

  const list = flatten
    ? flattenCollection(branch)
    : branch

  if (list) {
    Object.keys(list).forEach((key) => {
      if (list[key]?.meta?.menu?.[property]?.active) {
        stack.push(list[key])
      }
    })
  }

  return stack.sort((a, b) => a?.meta?.menu[property]?.order - b?.meta?.menu[property]?.order)
}

export const entriesInMonth = (data, month) => [
  ...new Set(data.filter((entry) => Object.is(entry.month, month))),
]

export const formatDay = (entry) => {
  let day = dayjs(entry.date).format(CONSTANTS.DATE_FORMATS.DAY)

  if (entry.dates) {
    const firstDate = entry.dates[0]
    const lastDate = entry.dates[entry.dates.length - 1]
    const firstMonth = dayjs(firstDate).startOf('month').format(CONSTANTS.DATE_FORMATS.FULL)
    const lastMonth = dayjs(lastDate).startOf('month').format(CONSTANTS.DATE_FORMATS.FULL)

    if (!Object.is(firstMonth, lastMonth)) {
      day = `${dayjs(firstDate).format(CONSTANTS.DATE_FORMATS.MONTH_DAY)}–${dayjs(lastDate).format(CONSTANTS.DATE_FORMATS.MONTH_DAY)}`
    } else {
      day = `${day}–${dayjs(lastDate).format(CONSTANTS.DATE_FORMATS.DAY)}`
    }
  }

  return day
}
