import {
  h, onMounted, onUpdated, ref,
} from 'vue'

import MarkdownIt from 'markdown-it'
import MarkdownItAbbr from 'markdown-it-abbr'
import MarkdownItAttrs from 'markdown-it-attrs'
import MarkdownItAnchor from 'markdown-it-anchor'
import MarkdownItContainer from 'markdown-it-container'
import MarkdownItCollapsible from 'markdown-it-collapsible'
import MarkdownItDeflist from 'markdown-it-deflist'
import MarkdownItEmoji from 'markdown-it-emoji'
import MarkdownItFootnote from 'markdown-it-footnote'
import MarkdownItHighlightjs from 'markdown-it-highlightjs'
import MarkdownItIns from 'markdown-it-ins'
import MarkdownItMark from 'markdown-it-mark'
import MarkdownItSub from 'markdown-it-sub'
import MarkdownItSup from 'markdown-it-sup'
import MarkdownItTasklists from 'markdown-it-task-lists'
import MarkdownItTOC from 'markdown-it-toc-done-right'

import MarkdownItIcons from './markdown-it-icons'
import MarkdownItJotformPopup from './markdown-it-jotform-popup'
import MarkdownItLineBreak from './markdown-it-line-break'
import MarkdownItModal from './markdown-it-modal'
import MarkdownItTimeFormat from './markdown-it-time-format'

const props = {
  anchor: {
    type: Object,
    default: () => ({}),
  },
  breaks: {
    type: Boolean,
    default: false,
  },
  emoji: {
    type: Object,
    default: () => ({}),
  },
  highlight: {
    type: Object,
    default: () => ({}),
  },
  html: {
    type: Boolean,
    default: false,
  },
  langPrefix: {
    type: String,
    default: 'language-',
  },
  linkify: {
    type: Boolean,
    default: false,
  },
  plugins: {
    type: Array,
    default: () => [],
  },
  quotes: {
    type: String,
    default: '“”‘’',
  },
  source: {
    type: String,
    default: '',
  },
  tag: {
    type: String,
    default: 'div',
  },
  footnoteLabel: {
    type: String,
    default: 'Notes',
  },
  simple: {
    type: Boolean,
    default: false,
  },
  tasklists: {
    type: Object,
    default: () => ({}),
  },
  toc: {
    type: Object,
    default: () => ({}),
  },
  typographer: {
    type: Boolean,
    default: false,
  },
  xhtmlOut: {
    type: Boolean,
    default: false,
  },
}

export default {
  name: 'vue3-markdown-it',
  props,
  // eslint-disable-next-line no-shadow
  setup(props) {
    const md = ref()

    const renderMarkdown = () => {
      const markdown = new MarkdownIt()
        .use(MarkdownItAbbr)
        .use(MarkdownItAttrs)
        .use(MarkdownItAnchor, props.anchor)
        .use(MarkdownItContainer, 'section', {
          render(tokens, idx) {
            if (tokens[idx].nesting === 1) {
              return '<section class="content-block">\n' // opening tag
            }
            return '</section>\n' // closing tag
          },
        })
        .use(MarkdownItContainer, 'aside', {
          render(tokens, idx) {
            if (tokens[idx].nesting === 1) {
              return '<aside>\n' // opening tag
            }
            return '</aside>\n' // closing tag
          },
        })
        .use(MarkdownItCollapsible)
        .use(MarkdownItDeflist)
        .use(MarkdownItEmoji, props.emoji)
        .use(MarkdownItFootnote)
        .use(MarkdownItHighlightjs, props.highlight)
        .use(MarkdownItIcons)
        .use(MarkdownItIns)
        .use(MarkdownItJotformPopup)
        .use(MarkdownItLineBreak)
        .use(MarkdownItMark)
        .use(MarkdownItModal)
        .use(MarkdownItSub)
        .use(MarkdownItSup)
        .use(MarkdownItTasklists, props.tasklists)
        .use(MarkdownItTimeFormat)
        .use(MarkdownItTOC, props.toc)
        .set({
          breaks: props.breaks,
          html: props.html,
          langPrefix: props.langPrefix,
          linkify: props.linkify,
          quotes: props.quotes,
          typographer: props.typographer,
          xhtmlOut: props.xhtmlOut,
        })

      props.plugins.forEach(({ plugin, options = {} }) => {
        markdown.use(plugin, options)
      })

      const fnLabel = props.footnoteLabel ? `<h4>${props.footnoteLabel}</h4>\n` : ''

      markdown.renderer.rules.footnote_block_open = () => (
        `<footer class="footnotes">\n${fnLabel}<ol>\n`
      )

      markdown.renderer.rules.footnote_block_close = () => (
        '</ol>\n</footer>\n'
      )

      md.value = props.simple
        ? markdown.renderInline(props.source)
        : markdown.render(props.source)
    }

    onMounted(() => renderMarkdown())
    onUpdated(() => renderMarkdown())

    return () => h(props.tag, { innerHTML: md.value })
  },
}
