import { flatten } from 'lodash'

const cacheBuster = '337342'

const CONTENT_ROOT = `/${cacheBuster}/content` // in /public, shipped in build as /dist
const FIXTURES_ROOT = `/${cacheBuster}/fixtures`  // in /public, shipped in build as /dist
const FORM_URI = 'https://form.jotform.com'
const MEDIA_URI = 'https://media.jo-ttt.com'
const PORTAL_URI = 'https://app.jackrabbitclass.com/jr3.0/ParentPortal/Login?orgID=541533'
const UPK_URI = 'https://upk.colorado.gov'

const MINOR_WORDS = {
  articles: ['a', 'an', 'the'],
  conjunctions: ['and', 'or', 'nor', 'but'],
  prepositions: ['at', 'by', 'for', 'in', 'of', 'if', 'on', 'with'],
  pronouns: ['me', 'my', 'i', 'you', 'he', 'him', 'his', 'she', 'her', 'hers', 'they', 'their', 'theirs', 'we', 'us', 'our', 'ours'],
  symbols: ['—', '–', '!', '@', '#', '$', '%', '^', '&', '*', ';', '.', '?'],
  other: ['which', 'should', 'could'],
}

const IGNORABLES = flatten(Object.values(MINOR_WORDS))

const DATE_FORMATS = {
  DAY: 'D',
  MONTH_DAY: 'MMM D',
  FULL: 'YYYY-MM-DD',
}

export default {
  CONTENT_ROOT,
  FIXTURES_ROOT,
  FORM_URI,
  MEDIA_URI,
  PORTAL_URI,
  UPK_URI,
  MINOR_WORDS,
  IGNORABLES,
  DATE_FORMATS,
}
