import { createRouter, createWebHistory } from 'vue-router'
import { contentPath, fixturesPath, mediaUrl } from '@/helpers'
import LayoutCalendar from '@/layouts/LayoutCalendar.vue'
import LayoutContent from '@/layouts/LayoutContent.vue'
import LayoutStaff from '@/layouts/LayoutStaff.vue'
import Layout404 from '@/layouts/Layout404.vue'
import Layout from '@/layouts/Layout.vue'

const redirects = [
  { path: '/index', redirect: '/' },
  { path: '/holidayclosure-dates', redirect: '/gymnastics-programs/closures' },
  { path: '/which-class-should-i-register-for', redirect: '/gymnastics-programs' },
  { path: '/recreational', redirect: '/gymnastics-programs/recreational' },
  { path: '/walking-through-3', redirect: '/gymnastics-programs/recreational' },
  { path: '/3-4-year-old-class', redirect: '/gymnastics-programs/recreational' },
  { path: '/45-6-year-old-class', redirect: '/gymnastics-programs/recreational' },
  { path: '/ages-6--up-class', redirect: '/gymnastics-programs/recreational' },
  { path: '/competitive-team', redirect: '/gymnastics-programs/competitive' },
  { path: '/team-information', redirect: '/gymnastics-programs/competitive' },
  { path: '/team-private-lessons', redirect: '/gymnastics-programs/private-lessons' },
  { path: '/class-schedulepricing', redirect: '/gymnastics-programs/options-and-rates' },
  { path: '/policies', redirect: '/gymnastics-programs/policies' },
  { path: '/covid-19-policies', redirect: '/gymnastics-programs/policies' },
  { path: '/faq', redirect: '/gymnastics-programs/faqs' },
  { path: '/preschool-programs', redirect: '/academic-preschool' },
  { path: '/early-childhood-enrichment-2-3-years', redirect: '/academic-preschool/early-childhood-enrichment' },
  { path: '/early-childhood-preschool-3-4-years', redirect: '/academic-preschool/early-childhood-preschool' },
  { path: '/pre-kindergarten-program-4-5-years', redirect: '/academic-preschool/pre-kindergarten-program' },
  { path: '/forms-information', redirect: '/academic-preschool/registration-forms' },
  { path: '/preschool-open-gym', redirect: '/open-gym' },
  { path: '/option--rates', redirect: '/summer-camp' },
  { path: '/forms-information--schedules', redirect: '/summer-camp' },
  { path: '/schedule-a-party', redirect: '/birthday-parties' },
  { path: '/frequently-asked-questions', redirect: '/birthday-parties' },
  { path: '/meet-our-staff', redirect: '/about' },
  { path: '/preschool-staff', redirect: '/academic-preschool/staff' },
  { path: '/gymnastics-staff', redirect: '/gymnastics-programs/staff' },
  { path: '/testimonials', redirect: '/about/testimonials' },
  { path: '/contact-us', redirect: '/contact' },
  { path: '/careers', redirect: '/jobs' },
]

const home = [
  {
    path: '/',
    component: Layout,
    meta: {
      crumb: 'Home',
      classname: 'home',
    },
    children: [
      { // home
        path: '',
        name: 'home',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              random: false,
              autoplay: true,
              slides: [
                { url: mediaUrl('/images/hero/facility.jpg') },
                { url: mediaUrl('/images/hero/meadow-room-1.jpg') },
                { url: mediaUrl('/images/hero/gym-wide-1.jpg') },
                { url: mediaUrl('/images/hero/reading-center.jpg') },
                { url: mediaUrl('/images/hero/beams-1.jpg') },
              ],
            },
          },
          title: '',
          menu: {
            main: {
              active: true,
              title: 'Home',
              order: 1,
            },
            footer: {
              active: true,
              title: 'Home',
              order: 1,
            },
          },
          content: contentPath('home.md'),
          crumb: 'Home',
          classname: 'home',
        },
      },
      { // Birthday Parties
        path: 'birthday-parties',
        name: 'parties',
        component: LayoutContent,
        meta: {
          header: {
            // image: '',
          },
          content: contentPath('birthday-parties.md'),
          title: 'Birthday Parties',
          menu: {
            main: {
              active: false,
              order: 4,
            },
            activity: {
              active: true,
              order: 50,
            },
          },
          crumb: 'Birthday Parties',
          classname: 'birthday-parties',
          search: {
            title: 'Birthday Parties',
            keywords: 'birthdays party parties events info costs fees prices',
          },
        },
      },
      { // Open Gym
        path: 'open-gym',
        name: 'open-gym',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              slides: [
                {
                  url: mediaUrl('/images/hero/open-gym.jpg'),
                  altText: 'Open Gym! Ages 6+: Every other Saturday 1:00–3:00pm; Preschool: Wednesdays 9:30–11:00am',
                },
              ],
            },
          },
          content: contentPath('open-gym.md'),
          title: 'Open Gym',
          menu: {
            main: {
              active: false,
              order: 5,
            },
            activity: {
              active: true,
              order: 40,
            },
          },
          crumb: 'Open Gym',
          classname: 'open-gym',
          search: {
            title: 'Open Gym',
            keywords: 'open gym play drop-in costs fees prices',
          },
        },
      },
      { // Parents Night Out
        path: 'parents-night-out',
        name: 'parents-night-out',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              slides: [
                { url: mediaUrl('/images/hero/2023-pno-summer.jpg') },
              ],
            },
          },
          content: contentPath('parents-night-out.md'),
          title: 'Parents Night Out',
          menu: {
            main: {
              active: false,
              order: 6,
            },
            parents: {
              active: true,
              order: 23,
            },
            activity: {
              active: true,
              order: 30,
            },
          },
          crumb: 'Parents Night Out',
          classname: 'parents-night-out',
          search: {
            title: 'Parents Night Out',
            keywords: 'parents night out pno activities activity pricing',
          },
        },
      },
      { // Summer Camp
        path: 'summer-camp',
        name: 'summer-camp',
        component: LayoutContent,
        meta: {
          // header: {
          //   slideshow: {
          //     slides: [
          //       { url: mediaUrl('/images/camps/2023-summer-camp-hero-b.jpg') },
          //     ],
          //   },
          // },
          content: contentPath('summer-camp_2025.md'),
          title: 'Summer Camp 2025',
          menu: {
            main: {
              active: true,
              order: 6,
              star: false,
            },
            activity: {
              active: true,
              hideMobile: true,
              // order: 10,
            },
          },
          crumb: 'Summer Camp',
          classname: 'summer-camp',
          search: {
            title: 'Summer Camp',
            keywords: 'summer camp teach school sessions rates options prices pricing',
          },
        },
      },
      { // Winter Camp
        path: 'winter-camp',
        name: 'winter-camp',
        component: LayoutContent,
        meta: {
          // header: {
          //   slideshow: {
          //     slides: [
          //       { url: mediaUrl('/images/hero/2022-winter-camp.jpg') },
          //     ],
          //   },
          // },
          content: contentPath('winter-camp.md'),
          title: 'Winter Gymnastics Camp',
          active: false,
          menu: {
            main: {
              active: false,
              title: 'Winter Camp 2022',
              order: 6,
            },
            activity: {
              active: false,
              title: 'Winter Camp',
              // order: 100,
            },
          },
          crumb: 'Winter Gymnastics Camp',
          classname: 'winter-camp',
          // search: {
          //   title: 'Winter Gymnastics Camp',
          //   keywords: 'winter camp gymnastics event sessions rates options prices pricing',
          // },
        },
      },
      { // Contact
        path: 'contact',
        name: 'contact-us',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              slides: [
                { url: mediaUrl('/images/hero/facility.jpg') },
              ],
            },
          },
          content: contentPath('contact.md'),
          title: 'Contact us',
          menu: {
            parents: {
              active: true,
              title: '',
              order: 25,
            },
            footer: {
              active: true,
              title: '',
              order: 10,
            },
          },
          crumb: 'Contact Us',
          classname: 'contact',
          search: {
            title: 'Contact Us',
            keywords: 'contact inquiry questions email phone address location map',
          },
        },
      },
      { // Jobs
        path: 'jobs',
        name: 'jobs',
        component: LayoutContent,
        meta: {
          content: contentPath('jobs.md'),
          title: 'Job Openings',
          menu: {
            footer: {
              active: true,
              title: '',
              order: 30,
            },
          },
          crumb: 'Job Openings',
          classname: 'job-openings',
          search: {
            title: 'Job Openings',
            keywords: 'teach coach work jobs staff',
          },
        },
      },
      { // 404
        path: ':pathMatch(.*)*',
        component: Layout404,
        name: 'not-found',
        meta: {
          content: contentPath('not-found.md'),
          title: 'Page Not Found',
          crumb: 'Page Not Found',
          classname: 'not-found',
        },
      },
    ],
  },
]

const gymnastics = [
  {
    path: '/gymnastics-programs',
    component: Layout,
    meta: {
      crumb: 'Gymnastics',
      classname: 'gymnastics',
      subNavTitle: 'Gymnastics Programs',
    },
    children: [
      { // Gymnastics
        path: '',
        name: 'gymnastics-programs',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              random: true,
              autoplay: false,
              slides: [
                { url: mediaUrl('/images/hero/beams-1.jpg') },
                { url: mediaUrl('/images/hero/gym-wide-2.jpg') },
                { url: mediaUrl('/images/hero/preteam.jpg') },
                { url: mediaUrl('/images/hero/foam-pit.jpg') },
                { url: mediaUrl('/images/hero/kids-beam.jpg') },
                { url: mediaUrl('/images/hero/gym-wide-1.jpg') },
              ],
            },
          },
          content: contentPath('gymnastics/index.md'),
          title: 'Gymnastics Programs',
          menu: {
            main: {
              active: true,
              title: 'Gymnastics',
              order: 2,
            },
            subnav: {
              active: true,
              title: 'JO Gymnastics',
              order: 1,
            },
          },
          classname: 'gymnastics',
          search: {
            title: 'Gymnastics Programs',
            keywords: 'gym team stars coach tumblers tumbling',
          },
        },
      },
      { // recreational
        path: 'recreational',
        name: 'recreational',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              slides: [
                { url: mediaUrl('/images/hero/kids-beam.jpg') },
              ],
            },
          },
          content: contentPath('gymnastics/recreational.md'),
          title: 'Recreational Gymnastics Programs',
          menu: {
            subnav: {
              active: true,
              title: 'Rec',
              order: 10,
            },
          },
          crumb: 'Recreational Programs',
          classname: 'recreational',
          search: {
            title: 'Recreational Gymnastics Programs',
            keywords: 'gym team stars coach tiny tots tiny tumblers',
          },
        },
      },
      { // competitive
        path: 'competitive',
        name: 'competitive',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              slides: [
                // { url: mediaUrl('/images/hero/team.jpg') },
                { url: mediaUrl('/images/jo/hero/team-2022-1.jpg') },
                { url: mediaUrl('/images/jo/hero/team-2022-2.jpg') },
                { url: mediaUrl('/images/jo/hero/team-2022-5.jpg') },
              ],
            },
          },
          content: contentPath('gymnastics/competitive.md'),
          title: 'Competitive Gymnastics',
          menu: {
            subnav: {
              active: true,
              title: 'Team',
              order: 20,
            },
          },
          crumb: 'Competitive Programs',
          classname: 'competitive',

          search: {
            title: 'Competitive Team Gymnastics',
            keywords: 'gym comp compmetition competitive team',
          },
        },
      },
      { // options and rates
        path: 'options-and-rates',
        name: 'options-and-rates',
        component: LayoutContent,
        meta: {
          content: contentPath('gymnastics/options-and-rates.md'),
          title: 'Gymnastics Options and Rates',
          menu: {
            subnav: {
              active: true,
              title: 'Schedule/Rates',
              order: 30,
            },
          },
          crumb: 'Schedule & Rates',
          classname: 'prices',
          search: {
            title: 'Gymnastics Schedule & Rates',
            keywords: 'gym class times schedules prices rates costs fees options team',
          },
        },
      },
      { // private lessons
        path: 'private-lessons',
        name: 'private-lessons',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              slides: [
                { url: mediaUrl('/images/hero/private-lessons.jpg') },
              ],
            },
          },
          content: contentPath('gymnastics/private-lessons.md'),
          title: 'Team Private Lessons',
          menu: {
            subnav: {
              active: true,
              title: 'Private Lessons',
              order: 40,
            },
          },
          crumb: 'Private Lessons',
          classname: 'lessons',
          search: {
            title: 'Team Private Lessons',
            keywords: 'gym comp team private lessons costs fees prices',
          },
        },
      },
      { // Policies
        path: 'policies',
        name: 'policies',
        component: LayoutContent,
        meta: {
          content: contentPath('gymnastics/policies.md'),
          title: 'Gymnastics Policies & Procedures',
          menu: {
            parents: {
              active: true,
              title: 'JO Policies & Procedures',
              order: 10,
            },
            subnav: {
              active: true,
              title: 'Policies',
              order: 50,
            },
          },
          crumb: 'Policies & Procedures',
          classname: 'policies',
          search: {
            title: 'Gymnastics Policies & Procedures',
            keywords: 'policies terms conditions procedures rules',
          },
        },
        children: [],
      },
      { // Drop Request
        path: 'drop-request',
        name: 'drop-request',
        component: LayoutContent,
        meta: {
          content: contentPath('gymnastics/drop-request.md'),
          title: 'Gymnastics Drop Request',
          menu: {
            footer: {
              active: true,
              title: 'Gymnastics Drop Request',
              order: 40,
            },
            parents: {
              active: true,
              title: 'Gymnastics Drop Request',
              order: 10,
            },
            subnav: {
              active: true,
              title: 'Drop Request',
              order: 60,
            },
          },
          crumb: 'Drop request',
          classname: 'drop-request',
          search: {
            title: 'Gymnastics Drop Request',
            keywords: 'drop cancel gymnastics leave quit withdraw',
          },
        },
        children: [],
      },
      { // FAQs
        path: 'faqs',
        name: 'FAQs',
        component: LayoutContent,
        meta: {
          content: contentPath('gymnastics/faqs.md'),
          title: 'Frequently Asked Questions',
          menu: {
            subnav: {
              active: true,
              title: 'FAQs',
              order: 70,
            },
          },
          crumb: 'FAQs',
          classname: 'faqs',
          search: {
            title: 'FAQs',
            keywords: 'faqs questions help',
          },
        },
        children: [],
      },
      { // gym calendars and closures
        path: 'closures',
        name: 'gym-calendar',
        component: LayoutCalendar,
        meta: {
          content: contentPath('gymnastics/calendars-and-closures.md'),
          calendarSource: fixturesPath('gym/calendar-data.json'),
          title: 'Gym Holidays & Closures',
          menu: {
            parents: {
              active: true,
              title: 'Gym Holidays & Closures',
              order: 15,
            },
            subnav: {
              active: true,
              title: 'Holidays/Closures',
              order: 80,
            },
          },
          crumb: 'Holidays & Closures',
          classname: 'gym-closures',
          search: {
            title: 'Gym Holidays & Closures',
            keywords: 'holidays closures dates holidayclosures calendar winter snow storm warning',
          },
        },
      },
      { // gym staff
        path: 'staff',
        name: 'gym-staff',
        component: LayoutStaff,
        meta: {
          content: contentPath('gymnastics/staff.md'),
          staffSource: fixturesPath('gym/staff-data.json'),
          title: 'Gym Staff',
          menu: {
            subnav: {
              active: true,
              title: 'Staff',
              order: 80,
            },
          },
          crumb: 'Gym Staff',
          classname: 'staff-gym',
          search: {
            title: 'Meet Our Gym Staff',
            keywords: 'about staff gym gymnastics coaches office jen chase alexas aragon',
          },
        },
      },
    ],
  },
]

const preschool = [
  {
    path: '/academic-preschool',
    alias: '/preschool',
    component: Layout,
    meta: {
      crumb: 'Academic Preschool',
      classname: 'preschool',
      subNavTitle: 'Academic Preschool',
    },
    children: [
      { // Preschool
        path: '',
        name: 'academic-preschool',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              random: true,
              autoplay: false,
              slides: [
                { url: mediaUrl('/images/hero/treehouse-room-1.jpg') },
                { url: mediaUrl('/images/hero/coloring.jpg') },
                { url: mediaUrl('/images/hero/garden-room-1.jpg') },
                { url: mediaUrl('/images/hero/kids-snow.jpg') },
                { url: mediaUrl('/images/hero/playing-blocks-1.jpg') },
                { url: mediaUrl('/images/hero/blocks.jpg') },
              ],
            },
          },
          content: contentPath('preschool/index.md'),
          title: 'Academic Preschool',
          menu: {
            main: {
              active: true,
              title: 'Preschool',
              order: 3,
            },
            subnav: {
              active: true,
              title: '',
              order: 1,
            },
          },
          classname: 'preschool',
          search: {
            title: 'Academic Preschool',
            keywords: 'class room teach teacher school academic preschool',
          },
        },
      },
      { // early-childhood 2.5-3
        path: 'early-childhood-enrichment',
        name: 'early-childhood-enrichment',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              slides: [
                { url: mediaUrl('/images/hero/laughing.jpg') },
              ],
            },
          },
          content: contentPath('preschool/class-enrichment.md'),
          content2: contentPath('_fragments/_preschool/_extentions.md'),
          title: 'Early Childhood Enrichment',
          menu: {
            subnav: {
              active: true,
              title: 'Ages 2½–3',
              order: 10,
            },
          },
          crumb: 'Early Childhood Enrichment',
          classname: 'ece',
          search: {
            title: 'Early Childhood Enrichment (Ages 2½–3)',
            keywords: 'school preschool early teach class room ece enrichment',
          },
        },
      },
      { // preschool 3-4
        path: 'early-childhood-preschool',
        name: 'early-childhood-preschool',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              slides: [
                { url: mediaUrl('/images/hero/reading-2a.jpg') },
              ],
            },
          },
          content: contentPath('preschool/class-preschool.md'),
          content2: contentPath('_fragments/_preschool/_extentions.md'),
          title: 'Early Childhood Preschool',
          menu: {
            subnav: {
              active: true,
              title: 'Ages 3–4',
              order: 20,
            },
          },
          crumb: 'Early Childhood Preschool',
          classname: 'ecp',
          search: {
            title: 'Early Childhood Preschool (Ages 3–4)',
            keywords: 'school preschool early teach class room ece',
          },
        },
      },
      { // pre-k 4-5
        path: 'pre-kindergarten-program',
        name: 'pre-kindergarten-program',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              slides: [
                { url: mediaUrl('/images/hero/circle-time-pre-k.jpg') },
              ],
            },
          },
          content: contentPath('preschool/class-pre-k.md'),
          content2: contentPath('_fragments/_preschool/_extentions.md'),
          title: 'Kindergarten Readiness',
          menu: {
            subnav: {
              active: true,
              title: 'Ages 4–5',
              order: 30,
            },
          },
          crumb: 'Kindergarten Readiness',
          classname: 'pre-k',
          search: {
            title: 'Kindergarten Readiness (Ages 4–5)',
            keywords: 'school preschool early teach class room ece pre-k prek kindergarten',
          },
        },
      },
      { // options and rates
        path: 'options-and-rates',
        name: 'classes',
        component: LayoutContent,
        meta: {
          content: contentPath('preschool/options-and-rates_2025-2026.md'),
          title: 'Preschool Options and Rates',
          menu: {
            subnav: {
              active: true,
              title: 'Options/Rates',
              order: 40,
            },
          },
          crumb: 'Class Options & Rates',
          search: {
            title: 'Preschool Options & Rates',
            keywords: 'preschool class times prices rates costs fees options',
          },
        },
      },
      { // forms
        path: 'registration-forms',
        name: 'registration-forms',
        component: LayoutContent,
        meta: {
          header: {
            slideshow: {
              slides: [
                { url: mediaUrl('/images/hero/pencils.jpg') },
              ],
            },
          },
          content: contentPath('preschool/forms-and-info.md'),
          title: 'Preschool Forms & Info',
          menu: {
            subnav: {
              active: true,
              title: 'Forms/Info',
              order: 50,
            },
          },
          crumb: 'Forms & Info',
          search: {
            title: 'Preschool Preschool Forms & Info',
            keywords: 'preschool registration forms info welcome packet asthma alergy alergies sick health covid',
          },
        },
      },
      { // preschool calendars
        path: 'calendars',
        name: 'preschool-calendars',
        component: LayoutCalendar,
        meta: {
          content: contentPath('preschool/calendars-and-closures.md'),
          calendarSource: fixturesPath('preschool/calendar-data.json'),
          title: 'School Calendars and Closures',
          menu: {
            parents: {
              active: true,
              title: 'School Calendars',
              order: 20,
            },
            subnav: {
              active: true,
              title: 'Calendars/Closures',
              order: 60,
            },
          },
          crumb: 'Calendars & Closures',
          search: {
            title: 'School Calendars & Closures',
            keywords: 'holidays closures dates holidayclosures calendar preschool events winter snow storm warning',
          },
        },
      },
      { // preschool staff
        path: 'staff',
        name: 'preschool-staff',
        component: LayoutStaff,
        meta: {
          content: contentPath('preschool/staff.md'),
          staffSource: fixturesPath('preschool/staff-data.json'),
          title: 'Preschool Teachers & Staff',
          menu: {
            subnav: {
              title: 'Staff',
              active: true,
              order: 70,
            },
          },
          crumb: 'Teachers & Staff',
          classname: 'staff-preschool',
          search: {
            title: 'Meet Our Preschool Staff',
            keywords: 'about staff preschool teachers office jen chase melissa roybal',
          },
        },
      },
    ],
  },
]

const about = [
  { // About / Staff
    path: '/about',
    component: Layout,
    meta: {
      crumb: 'About Us',
      classname: 'about',
      subNavTitle: 'About Us',
    },
    children: [
      {
        path: '',
        name: 'about',
        component: LayoutContent,
        meta: {
          content: contentPath('about/index.md'),
          title: 'About Us',
          menu: {
            parents: {
              active: true,
              order: 30,
            },
            footer: {
              active: true,
              order: 20,
            },
            subnav: {
              active: true,
              order: 1,
            },
          },
          crumb: '',
          classname: 'about',
          search: {
            title: 'About Us',
            keywords: 'about staff teachers coaches office jen chase melissa roybal',
          },
        },
      },
      {
        path: 'preschool',
        name: 'about-preschool',
        redirect: '/academic-preschool/staff',
      },
      {
        path: 'gym',
        name: 'about-gym',
        redirect: '/gymnastics-programs/staff',
      },
      {
        path: 'testimonials',
        name: 'about-testimonials',
        component: LayoutContent,
        meta: {
          content: contentPath('about/testimonials.md'),
          title: 'Testimonials',
          crumb: 'Testimonials',
          classname: 'testimonials',
          search: {
            title: 'Parent Testimonials',
            keywords: 'about testimonials reviews comments',
          },
        },
      },
    ],
  },
]

const routes = [
  ...redirects,
  ...home,
  ...gymnastics,
  ...preschool,
  ...about,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 } // always scroll to top
  },
})

export default router

// EXAMPLE CODE-SPLITTING / CHUNKING
// {
//   path: '/about',
//   name: 'About',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
// },
