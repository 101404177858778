<template>
  <Markdown
    v-if="content.length"
    :tag="tag"
    :footnote-label="footnoteLabel"
    :source="content"
    :html="true"
    :breaks="true"
    :linkify="false"
    :anchor="{ level: [2, 3] }"
    :toc="toc"
  />
</template>

<script setup>
import axios from 'axios'
import { useRoute } from 'vue-router'
import { defineProps, onMounted, ref } from 'vue'

import CONSTANTS from '@/constants'
import store from '@/store'

import Markdown from '@/modules/markdown'

const route = useRoute()

const props = defineProps({
  file: {
    type: String,
    required: true,
  },
  tag: {
    type: String,
    default: 'section',
  },
  footnoteLabel: {
    type: String,
    default: 'Notes',
  },
})

const content = ref('Loading...')

const toc = ref({
  level: 2,
  listType: 'ul',
  listClass: 'link-list',
})

onMounted(async () => {
  axios.get(props.file)
    .then((response) => {
      content.value = response.data
        .replaceAll('@FORM_URL', CONSTANTS.FORM_URI)
        .replaceAll('@MEDIA_URL', CONSTANTS.MEDIA_URI)
        .replaceAll('@PORTAL_URL', CONSTANTS.PORTAL_URI)
        .replaceAll('@UPK_URL', CONSTANTS.UPK_URI)

      if (props.file.includes('_forms') && content.value.includes('<iframe')) {
        store.dispatch('setModalContext', 'has-iframe')
      }
    })
    .catch((error) => {
      if (error.response?.status === 404) {
        content.value = `Not Found: ${props.file}`
      } else {
        content.value = `Error: ${error.response?.status}: ${error.response?.statusText}`
      }
    })

  if (!content.value) {
    content.value = `Not Found: ${route.meta.content}`
  }
})
</script>
